/* eslint-disable no-unused-vars */
// src/components/ATV/ATV.js
import React, { useContext, useEffect, useState } from "react";
import "./ATV.css";
import { SiteContext } from "../../context/SiteContext";
import { db, doc, getDoc } from "../../firebase";
import { useSales } from "../../context/SalesContext";
import { format } from "date-fns";

const ATVValue = () => {
  const { dailySales, loading: salesLoading } = useSales();
  const { selectedSite, selectedDate } = useContext(SiteContext);
  const [loading, setLoading] = useState(true);
  const [ATVData, setATVData] = useState(0);
  const [ATVValue, setATVValue] = useState(null);

  useEffect(() => {
    const fetchATVData = async () => {
      if (!selectedSite || !selectedDate) {
        setATVData(0);
        setATVValue(null);
        setLoading(false);
        return;
      }

      setLoading(true);
      const date = format(selectedDate, "dd-MM-yyyy");

      try {
        const salesCountRef = doc(db, "stores", selectedSite, "summary", date);
        const salesCountSnap = await getDoc(salesCountRef);

        if (!salesCountSnap.exists()) {
          setATVData(0);
          setATVValue(null);
        } else {
          const salesData = salesCountSnap.data();
          const salesCount = salesData.count;
          const salesValue = salesData.totalSales;

          const ATV =
            salesCount !== 0 ? (salesValue / salesCount).toFixed(2) : 0;
          setATVData(ATV);
          setATVValue(ATV);
        }
      } catch (error) {
        console.error("Error fetching ATV data: ", error);
        setATVData(0);
        setATVValue(null);
      } finally {
        setLoading(false);
      }
    };

    fetchATVData();
  }, [selectedSite, selectedDate]);

  if (loading || salesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="atv-container">
      <div className="label">ATV</div>
      <div className="amount">${ATVValue || "0.00"}</div>
    </div>
  );
};

export default ATVValue;
