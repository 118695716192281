// src/components/SPLH/SPLH.js
import React, { useContext, useEffect, useState } from "react";
import "./SPLH.css";
import {
  db,
  doc,
  getDoc,
  collection,
  query,
  where,
  getAggregateFromServer,
  sum,
} from "../../firebase";
import { SiteContext } from "../../context/SiteContext";
import { format } from "date-fns";
import { useSales } from "../../context/SalesContext";

const SPLH = () => {
  const { dailySales, loading: salesLoading } = useSales();
  const { selectedSite, selectedDate } = useContext(SiteContext);
  const [loading, setLoading] = useState(true);
  const [totalLabourCost, setTotalLabourCost] = useState(0);
  const [SPLH, setSPLH] = useState(null);

  useEffect(() => {
    const fetchWagesData = async () => {
      if (!selectedSite || !selectedDate) {
        setTotalLabourCost(0);
        setSPLH(null);
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const departmentsDocRef = doc(
          db,
          "stores",
          selectedSite,
          "IntegrationInfo",
          "PayrollIntegration",
          "Departments",
          "departments"
        );
        const departmentsDocSnap = await getDoc(departmentsDocRef);

        if (!departmentsDocSnap.exists()) {
          setTotalLabourCost(0);
          setSPLH(null);
          setLoading(false);
          return;
        }

        const departments = departmentsDocSnap.data().departments;
        const date = format(selectedDate, "yyyy-MM-dd");

        const coll = collection(db, "shifts");
        const q = query(
          coll,
          where("date", "==", date),
          where("department_id", "in", departments)
        );

        const sumAggregateQuery = getAggregateFromServer(q, {
          totalLabourCost: sum("shiftLengthInHours"),
        });

        const snapshot = await sumAggregateQuery;
        const data = snapshot.data();
        const totalCost = data.totalLabourCost ?? 0;
        setTotalLabourCost(totalCost);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching wages data:", error);
        setTotalLabourCost(0);
        setSPLH(null);
        setLoading(false);
      }
    };

    fetchWagesData();
  }, [selectedSite, selectedDate]);

  useEffect(() => {
    if (totalLabourCost !== 0 && dailySales.amount !== 0) {
      const SPLH = dailySales.amount / totalLabourCost;
      setSPLH(SPLH.toFixed(2));
    } else {
      setSPLH(0);
    }
  }, [totalLabourCost, dailySales.amount]);

  if (loading || salesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="wage-percentage-container">
      <div className="label">Sales per Labour Hour</div>
      <div className="amount">{SPLH !== null ? `$${SPLH}` : "$0.00"}</div>
    </div>
  );
};

export default SPLH;
