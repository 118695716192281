/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { db } from "../../../firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { SiteContext } from "../../../context/SiteContext";
import "./mapping-modal.css";

const MappingModal = ({ onClose }) => {
  const { selectedCompany } = useContext(SiteContext);
  const [sites, setSites] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeMappings, setStoreMappings] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const [endpoint, setEndpoint] = useState("");

  useEffect(() => {
    const fetchSitesAndStores = async () => {
      if (selectedCompany) {
        try {
          console.log("Fetching data for selectedCompany:", selectedCompany);

          // Fetch Deputy Endpoint
          const deputyEndpoint = doc(
            db,
            "companies",
            selectedCompany,
            "IntegrationInfo",
            "DeputyInfo"
          );
          const endpointSnap = await getDoc(deputyEndpoint);
          if (endpointSnap.exists()) {
            const deputyData = endpointSnap.data();
            console.log("Deputy data:", deputyData);
            setIsConnected(deputyData.DeputyConnected);
            const endpoint = deputyData.endpoint;
            setEndpoint(endpoint);

            // Fetch Deputy Sites if endpoint is available
            if (endpoint) {
              console.log("Fetching sites for endpoint:", endpoint);
              const sitesData = [];
              // Fetch all subcollections under the endpoint document
              const subcollectionsSnap = await getDocs(
                collection(db, `deputyData/${endpoint}/locations`)
              );
              subcollectionsSnap.forEach((doc) => {
                const data = doc.data();
                sitesData.push({
                  id: data.Id,
                  name: data.CompanyName,
                });
              });
              console.log("Fetched sites:", sitesData);
              setSites(sitesData);
            }
          }

          // Fetch Stores and their current mappings
          const storesSnapshot = await getDocs(
            collection(db, "companies", selectedCompany, "Stores")
          );
          const storesData = storesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log("Fetched stores:", storesData);

          // Set initial store mappings based on current `department_ids`
          const initialMappings = {};
          storesData.forEach((store) => {
            initialMappings[store.id] = store.department_ids || [];
          });
          setStoreMappings(initialMappings);
          setStores(storesData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchSitesAndStores();
  }, [selectedCompany]);

  const handleCheckboxChange = (storeId, siteId) => {
    // Update store mappings when checkboxes are toggled
    setStoreMappings((prevState) => {
      const storeMapping = prevState[storeId] || [];
      if (storeMapping.includes(siteId)) {
        return {
          ...prevState,
          [storeId]: storeMapping.filter((id) => id !== siteId),
        };
      } else {
        return {
          ...prevState,
          [storeId]: [...storeMapping, siteId],
        };
      }
    });
  };

  const handleSave = async () => {
    // Save the mappings to Firestore
    for (const [storeId, siteIds] of Object.entries(storeMappings)) {
      const storePath = `companies/${selectedCompany}/Stores/${storeId}`;
      const storeDocRef = doc(db, storePath);

      // Ensure siteIds is an array
      const siteIdArray = Array.isArray(siteIds) ? siteIds : [siteIds];

      // Prepend the endpoint to each siteId
      const departmentIds = siteIdArray.map(
        (siteId) => `${endpoint}.${siteId}`
      );

      await updateDoc(storeDocRef, {
        department_ids: departmentIds,
      });
    }
    onClose();
  };

  return (
    <div className="mm-modal-overlay">
      <div className="mm-modal-content">
        <div className="mm-header">
          <div className="mm-title">Deputy Connection Settings</div>
          <div className="mm-subtitle">
            Select which Deputy Sites are connected to your HospoSense Stores
          </div>
        </div>
        {isConnected ? (
          <div className="mapping-form">
            {stores.map((store) => (
              <div key={store.id} className="store-box">
                <div className="store-title">{store.name}</div>
                <div className="locations">
                  {sites.map((site) => (
                    <div key={site.id} className="location-item">
                      <span>{site.name}</span>
                      <label className="toggle-container">
                        <input
                          type="checkbox"
                          id={`${store.id}-${site.id}`}
                          checked={(storeMappings[store.id] || []).includes(
                            site.id
                          )}
                          onChange={() =>
                            handleCheckboxChange(store.id, site.id)
                          }
                        />
                        <div className="mm-toggle">
                          <div className="mm-rectangle"></div>
                          <div className="mm-ellipse"></div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="button-group">
              <button className="button delete-button">
                Delete Connection
              </button>
              <button className="button reconnect-button">Reconnect</button>
              <button className="button save-button" onClick={handleSave}>
                Save Settings
              </button>
            </div>
          </div>
        ) : (
          <div className="connect-message">Please connect to Deputy first.</div>
        )}
      </div>
    </div>
  );
};

export default MappingModal;
